import React from "react"
import Loadable from "@loadable/component"

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 1
        },
        992: {
            items: 2
        }
    }
}

const ClientFeedback = ({title, bold}) => {
    const [display, setDisplay] = React.useState(false)

    React.useEffect(() => {
        setDisplay(true)
    }, [])

    return (
        <section id="clients-feedback" className="testimonials-area ptb-100">
            <div className="container">
                <div className="text-center mb-5">
                    <h2 className="h3-title"> {title} <strong>{bold}</strong></h2>
                </div>

                {display ? <OwlCarousel
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Chiar astăzi am avut o problemă iar echipa SiteBunker m- a ajutat, ca de fiecare dată.
                                Mulțumesc și țineți- o tot așa. <a
                                    href="https://www.google.com/maps/contrib/113695891389124036583/place/ChIJVVWlvu3etkARPIbpUjllxaM"
                                    target="_blank">Publicat pe Google.</a></p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Ellys Shop </h3>
                                <a href='https://blogulcautat.ro/' target="_blank" className='main-color font-weight-bold'>blogulcautat.ro</a>
                                <span className="d-block">Iași, România</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Mereu am primit raspuns intr-un timp surprinzator de mic, indiferent de ziua si ora la
                                care ma deschis ticket. Platforma usor de folosit, preturi foarte avantajoase și
                                servicii excelente. Am recomandat la 10 persoane si voi recomanda in continuare. <a
                                    href="https://goo.gl/maps/yZoRjfGRmoNXbT4X9" target="_blank">Publicat pe Google.</a>
                            </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Cristi Medrea</h3>
                                 <a href='https://petplaza.ro/' target="_blank" className='main-color font-weight-bold'>petplaza.ro</a>
                                <span className="d-block">Romania</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Toti colegii dvs. la care au ajuns problemele semnalate de mine le-au rezolvat cu
                                promptitudine. In
                                plus, lucrul pe care il apreciez cel mai mult, m-au tratat ca pe un "necunoscator" ceea
                                ce este un lucru
                                foarte bun si frumos.</p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Iulia F</h3>
                                 <a href='https://gastroprofesional.ro/' target="_blank" className='main-color font-weight-bold'>gastroprofesional.ro</a>
                                <span className="d-block">Romania</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Va felicit pentru profesionalismul de care ati dat dovada, suntem foarte multumiti de
                                serviciile dvs.
                                Cu siguranta in momentul in care o sa expire hostingul pentru celelalte domenii o sa
                                achizitionam un alt
                                pachet de hosting de la dvs. (detinem peste 25 de domenii).</p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Cristian P</h3>
                                 <a href='https://3dgifts.ro/' target="_blank" className='main-color font-weight-bold'>3dgifts.ro</a>
                                <span className="d-block">Romania</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Oamenii răspund aproape instant la tickete, suportul e genial. Recomand cu încredere. <a
                                href="https://goo.gl/maps/m23T8cqNWtGAYwiT6" target="_blank">Publicat pe Google.</a></p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Petru Calugareanu</h3>
                                 <a href='https://www.tng-outdoor.ro/' target="_blank" className='main-color font-weight-bold'>tng-outdoor.ro</a>
                                <span className="d-block">Romania</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Orice problemă întâmpinată a fost rezolvată în mai puțin de 30 de minute. Recomand cu
                                mare încredere! <a href="https://goo.gl/maps/yrRe3AyJA11NbQq17" target="_blank">Publicat
                                    pe Google.</a></p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Kiş Ştefan</h3>
                                 <a href='https://www.telescoape.ro/' target="_blank" className='main-color font-weight-bold'>telescoape.ro</a>
                                <span className="d-block">Romania</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <div className="client-testim">
                            <p>Raspuns aproape instant la orice ticket deschis, indiferent de ora. M-au ajutat si cu o
                                configurare interna free of charge. Recomand! <a
                                    href="https://goo.gl/maps/1RS1oopGTELB7juH7" target="_blank">Publicat pe Google.</a>
                            </p>
                        </div>

                        <div className="client-info">
                            <div className="title">
                                <h3>Cristi P.</h3>
                                 <a href='https://thecon.ro/' target="_blank" className='main-color font-weight-bold'>thecon.ro</a>
                                <span className="d-block">Romania</span>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ""}
            </div>
        </section>
    )
}

export default ClientFeedback
